@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 2rem;
  line-height: 50px;
  font-family: "Open+Sans";
  font-weight: 600;
}

p {
  font-size: 1rem;
  line-height: 35px;
  font-family: "Andada Pro", serif;
  font-weight: 400;
}

ul li {
  
  line-height: 35px;
  font-family: "Andada Pro", serif;
  font-weight: 400;

}