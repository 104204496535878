.image-container:hover .image {
    /* Your hover styles for the image */
    opacity: 0.7; /* Example: Reducing opacity on hover */
}

.custom-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px; /* Adjust width as needed */
    height: 48px; /* Adjust height as needed */
    border-radius: 50%; /* Make it circular */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    transition: transform 0.3s ease; /* Add a smooth transition */
}

.custom-div:hover {
    transform: scale(1.1); /* Scale up a bit on hover */
}
