



  /* === HEADING STYLE #1 === */
  
  
  /* Style 9
     ----------------------------- */
  .nine h1 {
    text-align:center; font-size:50px;  color:red; letter-spacing:1px;
    font-family:"Playfair Display", serif; font-weight:bolder;
  }
  .nine h1 span {
    margin-top: 5px;
      font-size:15px; color:#274bc1; word-spacing:1px; font-weight:normal; letter-spacing:2px;
      text-transform: uppercase; font-family:"Raleway", sans-serif; font-weight:500;
  
      display: grid;
      grid-template-columns: 1fr max-content 1fr;
      grid-template-rows: 27px 0;
      grid-gap: 20px;
      align-items: center;
  }
  
  .nine h1 span:after,.nine h1 span:before {
      content: " ";
      display: block;
      border-bottom: 1px solid #ccc;
      border-top: 1px solid #ccc;
      height: 5px;
    background-color:#f8f8f8;
  }
  
  
 

/* button */


   a.b {
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    transition: filter 250ms;
    user-select: none;
    touch-action: manipulation;
    align-items: center;
    text-decoration: none; /* Added for anchor tag */
}

.shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: hsl(0deg 0% 0% / 0.25);
    will-change: transform;
    transform: translateY(2px);
    transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
}

.edge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: linear-gradient(to left, hsl(340deg 100% 16%) 0%, hsl(340deg 100% 32%) 8%, hsl(340deg 100% 32%) 92%, hsl(340deg 100% 16%) 100%);
}

.front {
    display: block;
    position: relative;
    padding: 12px 27px;
    border-radius: 12px;
    font-size: 1.1rem;
    color: white;
    background: hsl(345deg 100% 47%);
    will-change: transform;
    transform: translateY(-4px);
    transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
}

a.b:hover {
    filter: brightness(110%);
}

a.b:hover .front {
    transform: translateY(-6px);
    transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
}

a.b:active .front {
    transform: translateY(-2px);
    transition: transform 34ms;
}

a.b:hover .shadow {
    transform: translateY(4px);
    transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
}

a.b:active .shadow {
    transform: translateY(1px);
    transition: transform 34ms;
}

a.b:focus:not(:focus-visible) {
    outline: none;
}

.one h1 {
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 5px;
  }
  .one h1:before {
    width: 28px;
    height: 5px;
    display: block;
    content: "";
    position: absolute;
    bottom: 3px;
    left: 50%;
    margin-left: -14px;
    background-color: #b80000;
  }
  .one h1:after {
    width: 100px;
    height: 1px;
    display: block;
    content: "";
    position: relative;
    margin-top: 25px;
    left: 50%;
    margin-left: -50px;
    background-color: #b80000;
  }
  
