/* footer section start */
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

.ft {
    font-size: 14px;
    background: rgb(10, 68, 127);
    max-width: 1920px;
    margin: 0 auto;
    overflow-x: hidden;
    font-family: poppins;
}

#footer {
    background: #f7f7f7;
    padding: 3rem;
    padding-top: 7rem;
    padding-bottom: 80px;
    background: rgb(10, 68, 127);
}

#footer2 {
    background: #f7f7f7;
    padding: 3rem;
    margin-top: 0px;
    padding-top: 7rem;
    padding-bottom: 80px;
}

.social-links h2 {
    padding-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
}

.social-links img {
    padding-bottom: 25px;
}

.social-icons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: white;
}

.social-icons a {
    color: white;
}

.social-icons a:hover {
    color: orange;
}

.social-icons a i {
    padding: 0.4rem 1rem;
    border-radius: 3px;
    color: white;
    font-size: 16px;
    margin-right: 12px;
}

li {
    list-style: none;
}

.useful-link h2 {
    padding-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
}

.useful-link img {
    padding-bottom: 15px;
}

.use-links {
    line-height: 32px;
}

.use-links li i {
    font-size: 14px;
    padding-right: 8px;
    color: white;
}

.use-links li a {
    color: white;
    font-size: 15px;
    font-weight: 500;
}

.use-links li a:hover {
    color: orange;
}

.address h2 {
    padding-top: 15px;
    font-size: 20px;
    font-weight: 600;
    line-height: 36px;
}

.address img {
    padding-bottom: 15px;
}

.address-links li a {
    color: white;
    font-size: 15px;
    font-weight: 500;
}

.address-links li i {
    font-size: 16px;
    padding-right: 8px;
    color: white;
}

.address-links .address1,
.address-links .phone-item,
.address-links .email-item {
    font-weight: 500;
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-left: -10px; /* Shift content to the left */
}

.address-links {
    line-height: 32px;
    color: white;
}

.copy-right-sec {
    padding: 1rem;
    background: orange;
    color: white;
    text-align: center;
    font-size: larger;
}

.copy-right-sec a {
    color: #fcd462;
    font-weight: 500;
}

a {
    text-decoration: none;
}

/* footer section end */

@media (max-width: 575.98px) {
    .social-links {
        margin-left: 0 !important;
    }
}
