

.pulse {
    animation: pulse-animation 2s infinite;
  }
  
  @keyframes pulse-animation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .zoom-in-out {
    animation: zoom-animation 2s infinite alternate;
  }
  
  @keyframes zoom-animation {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }