
  .fe {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: orange;
    padding: 50px;
  }
  
  .cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .card {
    margin: 40px;
    position: relative;
    max-width: 250px;
    max-height: 350px;
    box-shadow: 0 40px 60px -6px black;
  }
  
  .card-title {
    display: block;
    text-align: center;
    color: #fff;
    background-color: #6184a8;
    padding: 2%;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  
  .card img {
    width: 100%;
    height: 98%;
    object-fit: cover;
    display: block;
    position: relative;
  }
  
  .card-desc {
    display: block;
    font-size: 1.2rem;
    position: absolute;
    height: 0;
    top: 0;
    opacity: 0;
    padding: 18px 8%;
    background-color: white;
    overflow-y: scroll;
    transition: 0.8s ease;
  }
  
  .card:hover .card-desc {
    opacity: 1;
    height: 100%;
  }
 
  