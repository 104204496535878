

   .herotrial {
    display: grid;
    position: relative;
    grid-template-columns: 100vw;
    grid-template-rows: 100vh;
    place-items: center;
    overflow: hidden;
    animation: clip-hero-anim 1.25s cubic-bezier(0.29, 0.8, 0.8, 0.98);
    will-change: clip-path;
  }
  
  .herotrial__bg,
  .herotrial__cnt {
    align-self: center;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  
  .herotrial__bg {
    display: grid;
    position: relative;
    z-index: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    place-items: center;
    animation: fade-in 0.75s linear;
    will-change: opacity;
  }
  
  .herotrial__bg::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 5;
    top: -10%;
    right: -10%;
    bottom: -10%;
    left: -10%;
    background: rgba(41, 4, 47, 0.4);
    background-blend-mode: screen;
  }
  
  .herotrial__bg picture {
    display: flex;
    height: 100%;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: 77% 50%;
    animation: scaling-hero-anim 4s 0.25s cubic-bezier(0, 0.71, 0.4, 0.97) forwards;
    will-change: transform;
  }
  
  .herotrial__cnt {
    display: grid;
    position: relative;
    place-items: center;
    z-index: 10;
    color: #FFF;
    font-size: 2.5vw;
    text-transform: uppercase;
    opacity: 0;
    animation: fade-in 0.75s 1.5s linear forwards;
  }
  
  .herotrial__cnt svg {
    height: 12vw;
  }
  
  .herotrial__cnt svg path {
    fill: #FFF;
  }
  
  .herotrial__cnt h1 {
    margin-bottom: 0;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes scaling-hero-anim {
    from {
      transform: scale(1.25);
    }
    to {
      transform: scale(1.1);
    }
  }
  
  @keyframes clip-hero-anim {
    from {
      clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
    }
    to {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
  }
  
  @media screen and (max-width: 768px) {
    .herotrial__cnt {
      font-size: 3vw; 
    }
  
    .herotrial__bg picture {
      object-position: center; 
      height: 300px;

    }
  }
  
  @media screen and (max-width: 762px){
  .herotrial{
    grid-template-rows: 35vh;
  }

  .herotrial__cnt{
    margin: 45px;
  }
}