@keyframes left-to-right {
    from {
        opacity: 0;
        transform: translateX(-50%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.animate-left-to-right {
    animation: left-to-right 1s ease-out;
}
