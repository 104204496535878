/* Base styles omitted for brevity */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px 30px;
}

.navbar-logo img {
  width: 165px;
}

.navbar-nav {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  position: relative;
}

.nav-link {
  display: block;
  padding: 10px 15px;
  color: rgb(10, 68, 127);
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;
  transition: color 0.3s ease; /* Added transition effect */
}

.nav-link:hover {
  color: orange !important;
}

/* Dropdown Menu Styles */
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 200px;
  background-color: orange;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease; /* Added transition effect */
}

.nav-item:hover .dropdown-menu {
  display: block;
}

.dropdown-menu .nav-link {
  padding: 10px;
  color: white;
  text-decoration: none;
}

.dropdown-menu .nav-link:hover {
  background-color: rgb(10, 68, 127);
}

/* Responsive styles */
.navbar-toggler {
  display: none;
}

@media (max-width: 768px) {
  .navbar-nav {
    flex-direction: column;
    width: 100%;
  }

  .navbar-collapse {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .navbar-collapse.show {
    display: flex;
  }

  .navbar-toggler {
    display: block;
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
  }
}
