/* Keyframes for animations */
@keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes float {
    0% {
      transform: translate3d(0, 0, 0);
    }
    50% {
      transform: translate3d(0, -10px, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }
  
  @keyframes slide-left {
    0% {
      transform: translateX(50px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  /* Animation classes */
  .animate-bounce-float {
    animation: bounce 1s ease infinite, float 1.5s ease infinite alternate;
  }
  
  .animate-bounce {
    animation: bounce 1s ease infinite;
  }
  
  .animate-float {
    animation: float 1s ease infinite alternate;
  }
  
  .animate-slide-left {
    animation: slide-left 0.8s ease forwards;
  }
  